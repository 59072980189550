import { Manager, Reference, Popper } from 'react-popper';
import DatePicker from 'react-date-picker/dist/DatePicker';
import ExtendedDateInput from './ExtendedDateInput';
import Calendar from './calendar/Calendar';
import { isValidDate } from 'utils/helpers';

const baseClassName = 'react-date-picker';
const rectTopAttr = 'data-rect-top';
const rectLeftAttr = 'data-rect-left';
const popperModifiers = [
  {
    name: 'flip',
    options: {
      fallbackPlacements: ['top-start'],
    },
  },
  {
    name: 'afterWrite',
    enabled: true,
    phase: 'write',
    requires: ['updateState'],
    fn: ({ state: { elements: { reference } } }) => {
      const { top, left } = reference.getBoundingClientRect();
      reference.setAttribute(rectTopAttr, top);
      reference.setAttribute(rectLeftAttr, left);
    },
  },
];

export default class ExtendedDatePicker extends DatePicker {
  componentDidMount() {
    this.handleOutsideActionListeners();
    this.wrapper.addEventListener('keydown', this.onWrapperKeyDown);
    this.wrapper.addEventListener('keyup', this.onWrapperKeyUp);
    window.addEventListener('orientationchange', this.onOrientationChange);
  }

  componentWillUnmount() {
    this.handleOutsideActionListeners(false);
    this.wrapper.removeEventListener('keydown', this.onWrapperKeyDown);
    this.wrapper.removeEventListener('keyup', this.onWrapperKeyUp);
    window.removeEventListener('orientationchange', this.onOrientationChange);
  }

  openCalendar = () => {
    if (this.state.isOpen)
      return;

    this.setState({ isOpen: true });
  };

  closeCalendar = () => {
    if (!this.state.isOpen)
      return;

    this.setState({ isOpen: false });
  };

  onOutsideAction = event => {
    if (!this.wrapper)
      return;

    const isInternalEvent = this.wrapper.contains(event.target);
    // Safari (desktop and mobile) fires focusin event on layout container (div#layout) when clicking/tapping inside calendar,
    // which resulted in calendar closed before value selected. Three conditional statements below fix this and make calendar
    // closing behavior consistent across all browsers.
    if (event.type === 'mousedown')
      this.calendarIsActive = isInternalEvent;

    if (event.type === 'focusin' && !isInternalEvent && event.target.id !== 'layout')
      this.calendarIsActive = false;

    if (this.calendarIsActive || isInternalEvent)
      return;

    this.closeCalendar();
  };

  onChange = value => {
    this.closeCalendar();

    const { onChange } = this.props;
    if (onChange)
      onChange(value);
  };

  onCalendarChange = value => {
    this.calendarIsActive = false;
    this.onChange(value);
    this.calendarButton.focus();
  };

  onOrientationChange = () => {
    if (!this.state.isOpen)
      return;

    this.closeCalendar();
  };

  onWrapperKeyUp = event => {
    if (event.key !== 'Enter' || event.target.tagName !== 'INPUT')
      return;

    if (!this.props.isValid)
      return;

    event.target.blur();
    this.closeCalendar();
  };

  onWrapperKeyDown = event => {
    if (!this.state.isOpen)
      return;

    if (event.key !== 'Escape')
      return;

    this.calendarButton.focus();
    this.closeCalendar();
  };

  clear = () => {
    this.calendarButton.focus();
    this.onChange(null);
  };

  onFocus = event => {
    if (this.iconIsActive)
      return;

    this.props.onFocus?.(event);
  };

  onKeyUp = event => {
    if (this.props.disabled || event.target.tagName !== 'INPUT')
      return;

    if (event.key === 'Spacebar' || event.key === ' ')
      this.openCalendar();
  };

  onClick = () => {
    if (this.props.disabled)
      return;

    this.openCalendar();
  };

  // Mac OS Safari do not fire focus events/sets focus on button click which broke onFocus handler behavior.
  // Property this.iconIsActive is used as workaround for this to make consistent behavior for all browsers.
  setIconActiveStatus = isActive => this.iconIsActive = isActive;

  onIconMouseDown = () => this.setIconActiveStatus(true);

  onIconMouseLeave = event => {
    if (
      event.relatedTarget
      && event.relatedTarget.nodeType
      && event.currentTarget.contains(event.relatedTarget)
    )
      return;

    this.setIconActiveStatus(false);
  };

  onClearIconClick = event => {
    event.stopPropagation();
    this.setIconActiveStatus(false);
    this.clear();
  };

  onCalendarIconClick = event => {
    event.stopPropagation();
    this.setIconActiveStatus(false);
    this.toggleCalendar();
  };

  renderInputs() {
    const {
      autoFocus,
      calendarAriaLabel,
      calendarIcon,
      clearAriaLabel,
      clearIcon,
      dayAriaLabel,
      dayPlaceholder,
      disableCalendar,
      disabled,
      format,
      locale,
      maxDate,
      maxDetail,
      minDate,
      monthAriaLabel,
      monthPlaceholder,
      name,
      nativeInputAriaLabel,
      required,
      returnValue,
      value,
      yearAriaLabel,
      yearPlaceholder,
      id,
    } = this.props;
    const { isOpen } = this.state;

    const [valueFrom] = [].concat(value);

    const ariaLabelProps = {
      dayAriaLabel,
      monthAriaLabel,
      nativeInputAriaLabel,
      yearAriaLabel,
    };

    const placeholderProps = {
      dayPlaceholder,
      monthPlaceholder,
      yearPlaceholder,
    };

    return (
      <Reference>
        {({ ref }) => (
          // The below eslint rule should be disabled as in fact here keyUp events captured not for element, but its children - input elements.
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div className={`${baseClassName}__wrapper`} tabIndex="-1" ref={ref} onKeyUp={this.onKeyUp} onClick={this.onClick}>
            <ExtendedDateInput
              {...ariaLabelProps}
              {...placeholderProps}
              autoFocus={autoFocus}
              className={`${baseClassName}__inputGroup`}
              disabled={disabled}
              format={format}
              isCalendarOpen={isOpen}
              locale={locale}
              maxDate={maxDate}
              maxDetail={maxDetail}
              minDate={minDate}
              name={name}
              onChange={this.onChange}
              required={required}
              returnValue={returnValue}
              value={valueFrom}
              id={id}
            />
            {clearIcon !== null && (
              <button
                aria-label={clearAriaLabel}
                className={`${baseClassName}__clear-button ${baseClassName}__button`}
                disabled={disabled}
                onMouseDown={this.onIconMouseDown}
                onMouseLeave={this.onIconMouseLeave}
                onClick={this.onClearIconClick}
                onFocus={this.stopPropagation}
                type="button"
              >
                {clearIcon}
              </button>
            )}
            {calendarIcon !== null && !disableCalendar && (
              <button
                aria-label={calendarAriaLabel}
                className={`${baseClassName}__calendar-button ${baseClassName}__button`}
                disabled={disabled}
                onMouseDown={this.onIconMouseDown}
                onMouseLeave={this.onIconMouseLeave}
                onClick={this.onCalendarIconClick}
                onFocus={this.stopPropagation}
                type="button"
                ref={ref => this.calendarButton = ref}
              >
                {calendarIcon}
              </button>
            )}
          </div>
        )}
      </Reference>
    );
  }

  renderCalendar() {
    const { disableCalendar } = this.props;
    const { isOpen } = this.state;

    if (isOpen === null || disableCalendar)
      return null;

    const {
      calendarClassName,
      className: datePickerClassName, // Unused, here to exclude it from calendarProps.
      isValid, // Unused, here to exclude it from calendarProps.
      onChange,
      value,
      calendarRef,
      ...calendarProps
    } = this.props;

    const className = `${baseClassName}__calendar`;

    return (
      <Popper strategy="absolute" placement="bottom-start" modifiers={popperModifiers} innerRef={node => this.popperNode = node}>
        {({ ref, style, update }) => {
          if (isOpen && style.transform) {
            const referenceElement = this.wrapper.firstElementChild;
            const prevTop = +referenceElement.getAttribute(rectTopAttr);
            const prevLeft = +referenceElement.getAttribute(rectLeftAttr);
            const { top, left } = referenceElement.getBoundingClientRect();
            if (prevTop !== top || prevLeft !== left)
              update();
          }

          return (
            <div className={`${className} ${className}--${isOpen ? 'open' : 'closed'}`} ref={ref} style={style}>
              <Calendar
                className={calendarClassName}
                onChange={this.onCalendarChange}
                value={isValidDate(value) ? value : null}
                ref={calendarRef}
                {...calendarProps}
              />
            </div>
          );
        }}
      </Popper>
    );
  }

  render() {
    const { className, disabled } = this.props;
    const { isOpen } = this.state;

    const datePickerClassNames = baseClassName +
      ` ${baseClassName}--${isOpen ? 'open' : 'closed'}` +
      ` ${baseClassName}--${disabled ? 'disabled' : 'enabled'}` +
      ` ${className || ''}`;
    return (
      // The below eslint rule should be disabled as in fact here focus events captured not for element, but its children - input elements.
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={datePickerClassNames}
        {...this.eventProps}
        onFocus={this.onFocus}
        ref={ref => {
          if (!ref)
            return;

          this.wrapper = ref;
        }}
      >
        <Manager>
          {this.renderInputs()}
          {this.renderCalendar()}
        </Manager>
      </div>
    );
  }
}
