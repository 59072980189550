import styles from './Checkbox.module.scss';
import { CheckedIcon } from 'components/primitives/icons';

type Props = {
  className?: string;
  title?: string | null;
};

const Checkbox = ({ className = '', title, ...attributes }: Props & Omit<JSX.IntrinsicElements['input'], 'className' | 'title' | 'type'>) => (
  <div className={styles.container}>
    <span className={`${styles.chb} ${className}`} title={title}>
      <input type="checkbox" {...attributes} />
      <ins aria-hidden>{/*ins right after input[type="checkbox"] to fix iOS bug*/}
        <CheckedIcon />
      </ins>
    </span>
  </div>
);

export default Checkbox;
